import React from 'react';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';



const Graphs = (props) => {

   return (
    <div className="graphs">
     
   <div id="dashboard" ref={loadDashboard} > </div>
    </div>
  );
};

function loadDashboard(targetElement) {
   // Clean target element
   targetElement.innerHTML = '';
  const options = {
    url: "https://us-east-1.quicksight.aws.amazon.com/embed/60e65e6de4a54134997ab62f82b0c153/dashboards/2917d755-e433-4157-8dff-776bbd8e0aad?code=AYABeGcLBRnuc01yX2E9ARhBMx4AAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4P-lAb3AsrVHPwO-wVCEmuFDTp0yz4wFfkrwutzPupewBrPXp9Kh-oHJwDZpFL4rZegAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDAAVoqmcwdNVq9e69gIBEIA7a9OIOlMWYvFKLBKq9FoJVWuU2s7_vvDsATGKOcQ9zJ9o_1f4exIbBg6YBZ3r432K-uDrJLt5j1CWESECAAAAAAwAABAAAAAAAAAAAAAAAAAAHzfQcvjrXhvNGBFRFiXC3v____8AAAABAAAAAAAAAAAAAAABAAAA5Qqk1R4lW4zJrRxQ7C5E52e0mzVBnwlmJ_btXQVA4S4BTUOV8vrScTggZuYAT1NkYRXRGa3jZu8MXoonTHSe_MOD4BTJqEfU4rq-KdOCa1mZGm86ytWm8h5DyQQZiZDhDco868yN10OmehGMRbJcejEM_aXsDHy7hkEWZy7mOr3wM8D_GN6hN7tyTxMNOPUdTNcPx7hmGl2g7q3Kg5t3xB9nrUNbVD7uzy5l8YzjNVTK_1zN_vrwzB0nc-2py1GNu7UncgzSiDhKikK_HBZMTGeU6IQn9dQJw_xb_-0yHDPR7Tp2YEDiHJ7gFGxHXXKrY43A8WJy&identityprovider=quicksight&isauthcode=true",
    container: targetElement,
    footerPaddingEnabled: true,
    scroll: "no",
    height: "AutoFit",
    loadingHeight: "300px",
  };
  const dashboard = embedDashboard(options);


  dashboard.on('load', () => {
    console.log('dashboard loaded');
  });
  dashboard.on('error', (err) => {
    console.error(err);
  });
}



export default Graphs;