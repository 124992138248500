import React from 'react';
import Graphs from './components/graphs/Graphs';

function App() {
  return (
    <div className="App">
      <Graphs/>
    </div>
  );
}

export default App;